@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=DM+Serif+Display:ital@0;1&display=swap");
.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
}
/* @import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap"); */
/* .dm-serif display-regular {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-style: normal;
} */
.App {
  text-align: center;
  /* color: rgb(250 204 21); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.scroller::-webkit-scrollbar {
  display: none;
}
/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 1400px;
  height: 100%;
}

.slideshowSlider {
  white-space: nowrap;
}

.slide {
  display: inline-block;

  height: 680px;
  width: 100%;
  border-radius: 40px;
}

/* Buttons */
.slideshowDots {
  text-align: center;
}
.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}
#myElement {
  visibility: hidden; /* Hide the element visually */
  position: absolute; /* Keep it in the document flow, if needed */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* In your styles.css (or styles.scss) file */

.spanClasses {
  @apply hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold;
}
.h1Classes {
  @apply py-5 text-center text-3xl lg:text-5xl  bebas-neue-regular text-blue-950;
}
.h2Classes {
  @apply py-10 text-blue-950 text-center text-2xl lg:text-3xl bebas-neue-regular;
}
.responsiveClasses {
  @apply lg2:px-32 px-5 lg3:px-72;
}
.pageContentClasses {
  @apply text-xs md:text-lg;
}
