._19xafot0 {
  --_19xafot1: 500ms;
  --_19xafot2: 0ms;
  --_19xafot3: translateY(1rem);
}
._19xafot4 {
  opacity: 0;
  transform: var(--_19xafot3);
  transition: opacity var(--_19xafot1) var(--_19xafot2), transform var(--_19xafot1) var(--_19xafot2);
}
._19xafot5 {
  opacity: 1;
  transform: translateY(0);
}
@media (prefers-reduced-motion) {
  ._19xafot4 {
    opacity: 1;
    transform: none;
    transition: none;
  }
  ._19xafot5 {
    transform: none;
  }
}