.box {
  position: relative;
  width: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1;
}

.box:before,
.box:after {
  content: "";
  width: 0;
  height: 100%;
  background: #72b5d9;
  opacity: 0.9;
  position: absolute;
  z-index: 1;
  transition: all 0.5s;
}

.box:hover:before,
.box:hover:after {
  width: 50%;
}

.box:before {
  left: 0;
  top: 0;
}

.box:after {
  right: 0;
  top: 0;
}

.box:hover img {
  transform: scale(1);
}

.box img {
  width: 100%;
  height: auto;
  transition: all 0s;
}

.box .box-content {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.box .content {
  padding: 20px;
  text-align: center;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}

.box .title {
  font-size: 25px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  margin: 0 0 5px;
  transform: rotateY(90deg) scale(0);
  transition: all 0.9s;
  font-weight: 700;
  font-family: gobold5 !important;
  letter-spacing: 0.7px;
}

.box:hover .title {
  transform: unset;
}

.box .post {
  color: #000;
  letter-spacing: 1px;
  text-transform: capitalize;
  display: block;
  transition: all 0.9s cubic-bezier(0.25, 0.1, 0.26, 1) 0.25s;
  font-weight: 700;
}

.box:hover .post {
  transform: unset;
}

.box .icon {
  text-align: center;
  padding: 0;
  margin: 0;
  list-style: none;
  transition: all 0.5s ease 0.45s;
}

.box:hover .icon {
  transform: rotateY(0) scale(1);
}

.box .icon li {
  display: inline-block;
  margin: 0 3px;
}

.box .icon li a {
  font-size: 14px;
  padding: 2px 14px;
  letter-spacing: 0.7px;
  color: #000;
  background-color: #f1cc4a;
  line-height: 34px;
  display: block;
  transition: all 0.3s;
  font-weight: 700;
  box-shadow: 0 0 2px #ffff85;
  transform: rotateY(90deg) scale(0);
}

.box:hover .icon li a {
  transform: unset;
}

a {
  text-decoration: none;
}
